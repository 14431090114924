#login {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15vh;
  align-items: center;
  justify-content: flex-start;
  color: black;
}

#user-warning p {
  font-size: 14px;
  width: 80%;
  margin-left: 10px;
}

#user-warning {
  width: calc(100%-12px);
  display: flex;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  background-color: #e91429;
  color: #fff;
  padding-left: 12px;
  font-weight: 500;
}

#user-warning p a {
  color: #fff;
}

/* Login Form Css */
#login form {
  background-color: rgba(128, 128, 128, 0.343);
  padding: 3em;
  margin-top: 2em;
  border-radius: 5px;
  width: 40%;
}
#login form h1 {
  text-align: center;
  font-size: 2.6em;
}
#login-form {
  margin: 1em auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #767676;
  padding: 10px 25%;
}

#login-form label {
  font-weight: bold;
  font-size: 0.9em;
}

#login-form input {
  width: calc(100%);
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  margin: 8px 0px 20px 0px;
  color: black;
  outline: none;
  height: 2.5em;
  background-color: transparent;
  border: 2px solid #767676;
}
#login-form input:hover,
#login-form input:active {
  border: 2px solid #501a77;
}

/* Switch Styling */

#remindmecontainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
#remindmecontainer label {
  margin-right: 10px;
  font-weight: 400;
}
.switch {
  --secondary-container: #501a77;
  --primary: #000000;
  font-size: 9px;
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

.switch input {
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #727272;
  transition: 0.2s;
  border-radius: 15px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 20px;
  left: 0.2em;
  bottom: 0.14em;
  background-color: #000000;
  transition: 0.4s;
}

input:checked + .slider::before {
  background-color: var(--primary);
}

input:checked + .slider {
  background-color: var(--secondary-container);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--secondary-container);
}

input:checked + .slider:before {
  transform: translateX(0.85em);
}

.login-next-btn {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 10px 0px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  background-color: #501a77;
  border-radius: 25px;
  margin: 1.5em 0px;
  align-self: flex-end;
}
.login-next-btn2 {
  width: 50%;
  margin: 10px 25%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 10px 0px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  background-color: #501a77;
  border-radius: 25px;
  align-self: flex-end;
}

.forgotpassword {
  color: #501a77;
  align-self: center;
}
.login-signin a {
  color: #501a77;
  margin-left: 2px;
}
.login-signin {
  align-self: center;
}
#login-passwordcontainer {
  position: relative;
}

#login-password-visibility {
  position: absolute;
  top: 34px;
  right: -6px;
}

@media screen and (max-width: 850px) {
  #login form {
    width: 80%;
    padding: 1em;
  }
  .login-next-btn2 {
    width: 76%;
    margin: 10px 12%;
  }

  #login-form {
    margin: 1em auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #767676;
    padding: 10px 10%;
  }
}
