@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.Marketing *:not(i) {
  font-family: "Inter", serif;
  font-weight: normal;
}
.Marketing img {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.389);
}
.Marketing h1 {
  font-size: 40px;
   font-family: "Playfair Display", serif;
   text-align: center;
   max-width: 1000px;
   margin: 40px auto;
   font-weight: bold;
  }
  .Marketing h1 span {
    color: #702b88;
    font-family: "Playfair Display", serif;
  font-weight: bold;
}

/* ===========LANDING PAGE ============ */

#landing-upper {
  background-color: #702b88;
  display: flex;
  padding: 10px;
  color: white;
  gap: 10px;
}
#landing-upper p:first-child {
  margin-right: auto;
}
.MrkLanding div {
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.MrkLanding div a {
  color: white;
  background-color: #702b88;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  width: fit-content;
}
.MrkLanding img {
  width: 400px;
  object-fit: contain;
  border-radius: 5px;
}
.MrkLanding p {
  font-size: 20px;
}
.MrkLanding h1 {
  text-align: start;
  margin: 0px;
}
.MrkLanding {
  min-height: calc(100vh - 30px);
  display: flex;
  gap: 40px;
  align-items: center;
  max-width: 1000px;
  margin: auto;
}

/* ==========WhytoChoose============= */
.WhytoChoose {
  background-color: #e7e2fa;
  padding: 3vh 0px;
}
.WhytoChoose > div {
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
  max-width: 1000px;
  margin: auto;
}
.wtc-img img {
  box-shadow: none;
  object-fit: contain;
  width: 350px;
}
.wtc-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.one-reason i {
  font-size: 20px;
  color: #702b88;
  background-color: #b5d55b;
  padding: 15px;
  border-radius: 3px;
}
.one-reason h2 {
  font-size: 22px;
}
.one-reason p {
  font-size: 16px;
}
.one-reason {
  display: flex;
  transition: all 200ms ease-in-out;
  padding: 10px;
  border-radius: 3px;
  align-items: center;
  gap: 10px;
}
.one-reason:hover {
  transform: scale(1.01);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

/* =========NEW TESTIMONIALS============ */

.NewTestimonials h1 {
  text-align: center;
}

.oneTestimonialCard * {
  margin: 0%;
  color: rgb(58 61 80);
  line-height: 1.25;
  padding: 0%;
}

.oneTestimonialCard {
  width: 350px;
  height: 150px;
  padding: 16px;
  flex-shrink: 0;
  background-color: rgb(255, 255, 255);
  border: 1px solid gray;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.oneTestimonialCard > div {
  display: flex;
  gap: 10px;
}

.oneTestimonialCard h4 {
  font-weight: 600;
}
.oneTestimonialCard > div img {
  height: 38px;
  width: 38px;
  border-radius: 100%;
}

.oneTestimonialCard > div span {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.alltestmonials {
  overflow: hidden;
  position: relative;
}

.one-row {
  display: flex;
  flex-direction: row;
  z-index: -1;
  width: fit-content;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  overflow: hidden;
  padding: 5px 0px;
}

.one-row:first-child {
  animation: scrollAnimationRight 20s linear infinite;
}

.one-row:last-child {
  animation: scrollAnimationLeft 20s linear infinite;
}

@keyframes scrollAnimationLeft {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-50%);
  }
}

.alltestmonials.hovered .one-row {
  animation-play-state: paused;
}

@keyframes scrollAnimationRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
.oneTestimonialCard:hover {
  box-shadow: 1px 1px 10px rgba(128, 128, 128, 0.507);
  transition: all 200ms ease;
  transform: scale(1.01);
}

/* ==========FAQ============= */
.FAQs {
  background-color: #e7e2fa;
  padding: 3vh 0px;
}
.faqs {
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 0 auto;
  padding: 15px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #f4f2fc00;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 18px;
  padding-right: 30px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
  font-size: 16px;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
.faqs .faq:hover {
  border: 1px solid #867d7d;
}

/* =============Warning============== */

.Warning1 h1 {
  background-color: #b5d552;
  border-radius: 5px;
  padding: 5px;
  color: white;
}
.Warning1 ul div span {
  color: #702b88;

  margin: auto 5px;
}
.Warning1 ul {
  display: flex;
  max-width: 1000px;
  font-size: 18px;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 50px;
  align-items: center;
  justify-content: space-between;
}
.Warning2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  align-items: center;
  padding: 50px 10px;
  background-color: #e7e2fa;
}
.Warning2 a {
  background-color: #702b88;
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
}

/* ======WhoThisFor========== */
.WhoThisFor {
  max-width: 1000px;
  margin: auto;
}
.WhoThisFor > p span {
  color: #702b88;
  font-size: 24px;
  font-style: italic;
  margin: auto 5px;
}
.WhoThisFor > p {
  text-align: center;
  font-size: 24px;
  font-style: italic;
  margin: 10px 0px;
}
.quote {
  font-style: italic;
  font-size: 24px;
  color: #702b88;
  margin: 10px;
}
.WhoThisFor > div {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}
.who-this-item h3 {
  color: #702b88;
  font-weight: bold;
}

.who-this-item {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  margin-bottom: 20px;
  gap: 10px;
}
.who-this-item img {
  border-radius: 5px;
}

@media screen and (max-width: 1000px) {
  .MrkLanding img {
    display: none;
  }
  .Marketing h1 {
  font-size: 36px;

}

.MrkLanding div {
  gap: 50px;
}
  .MrkLanding {
    padding: 0px 20px;
    padding-top: 10vh;
    display: block;
    min-height: 70vh;
  }

  .landing_upper_p {
    display: none;
  }
  .WhytoChoose > div {
    display: flex;
    padding: 0px 10px;
  }
  .wtc-img img {
    display: none;
  }

  .WhoThisFor > p {
    padding: 0px 10px;
    font-size: 24px;
    text-wrap: balance;
  }
  .who-this-item h3 {
    font-size: 24px;
  }
  .who-this-item {
    max-width: 100%;
  }
  .quote {
    font-size: 20px;
  }


  .FAQs > h1{
    margin: 5vh auto;

  }

  .faqs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0%;
  }
  .Warning1 ul{
    gap: 20px;
  }
  .Warning1 {
    padding: 0px 20px;
  }
}
