.UserCheckoutView {
  margin-top: 18vh;
  padding: 2em 0px;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  width: 100%;
  background-color: #ffffff;
}

.UserCheckoutView
  label:not(#wordline-select, #wordline-select-tc, #companyName)::after {
  content: " *";
  color: red;
}
.UserCheckoutView input[type="submit"] {
  background-color: #501a77;
  border: none;
  outline: none;
  font-size: 18px;
  width: 100%;
  cursor: pointer;
  color: white;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 7px;
}
.UserCheckoutView input {
  padding: 7px;
  height: 3em;
  border-radius: 3px;
  outline: none;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
}
.UserCheckoutView > * {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.UserCheckoutView .cartdetails div {
  height: fit-content !important;
  text-align: center;
  text-wrap: balance;
  padding: 10px 2px;
  font-size: 18px;
}
.UserCheckoutView .cartdetails {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.checkoutdetailheading {
  font-size: 2em;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  color: #501a77;
  border-bottom: 2px solid #501a77;
  padding-bottom: 2px;
  margin-bottom: 10px;
}

#wordline-select img {
  max-width: 160px;
  object-fit: contain;
  margin-left: auto;
}
#wordline-select {
  display: flex;
  background-color: #f3f4f6;
  height: fit-content !important;
  padding: 10px 10px;
  font-size: 18px;
  align-items: center;
  text-wrap: balance;
  gap: 5px;
}
#wordline-select-tc > ul {
  margin-left: 30px;
}
#wordline-select-tc {
  display: flex;
  flex-direction: column;
  background-color: #f3f4f6;
  height: fit-content !important;
  padding: 10px 10px;
  font-size: 18px;
  gap: 5px;
}
@media screen and (max-width: 950px) {
  .UserCheckoutView {
    flex-direction: column;
    align-items: center;
  }
  .UserCheckoutView > * {
    width: 90%;
  }
  .checkoutdetailheading {
    margin: 20px 0px;
  }
}
