.UserCardWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  z-index: 99;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.39);
}
#UserCard {
  z-index: 100;
  max-width: 400px;
  height: fit-content;
  margin: 0px 20px;
  margin-top: 10vh;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.514);
  border-radius: 10px;
}
@media screen and(max-width:440px) {
  #UserCard {
    width: 90%;
    margin: 0px;
    margin-top: 10vh;
  }
  .UserCardWrapper {
    align-items: center;
  }
}

#UserCard h1,
#UserCard p {
  margin-bottom: 10px;
  text-align: center;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  overflow-wrap: break-word;
}

#UserCard a:hover {
  background-color: rgba(187, 121, 248, 0.63);
}
#UserCard a {
  padding: 10px;
  border-radius: 2px;
  color: black;
  display: flex;
  gap: 10px;
  border-top: 1px solid black;
  text-decoration: none;
}

#UserCard a#logout {
  background-color: rgb(250, 51, 51);
}

.UserCartView {
  margin-top: 18vh;
  padding: 2em 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
  background-color: white;
}

.UserCartView > span {
  width: 86%;
  display: flex;
  margin-top: 20px;
  text-wrap: balance;
  align-items: center;
  justify-content: space-between;
}

.UserCartView > div {
  border-radius: 10px;
  padding: 20px 0px;
  height: 100%;
  gap: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.589);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
}
@media screen and (max-width: 700px) {
  .UserCartView > div {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
}

.UserCartView > div > div {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.editcartbtn {
  background-color: #501a77;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 7px;
}

.check-out-container {
  background-color: #ffffff;
  display: flex;
  width: 270px;
  height: 120px;
  position: relative;
  border-radius: 6px;
  transition: 0.3s ease-in-out;
}

.check-out-container:hover {
  transform: scale(1.03);
}

.check-out-container:hover .check-out-left-side {
  width: 100%;
}

.check-out-left-side {
  background-color: #5de2a3;
  width: 130px;
  height: 120px;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  flex-shrink: 0;
  overflow: hidden;
}

.check-out-right-side {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  justify-content: space-between;
  white-space: nowrap;
  transition: 0.3s;
}

.check-out-right-side:hover {
  background-color: #f9f7f9;
}

.check-out-arrow {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.check-out-new {
  font-size: 23px;
  font-family: "Lexend Deca", sans-serif;
  margin-left: 20px;
}

.check-out-card {
  width: 70px;
  height: 46px;
  background-color: #c7ffbc;
  border-radius: 6px;
  position: absolute;
  display: flex;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: 9px 9px 9px -2px rgba(77, 200, 143, 0.72);
  -moz-box-shadow: 9px 9px 9px -2px rgba(77, 200, 143, 0.72);
  -webkit-box-shadow: 9px 9px 9px -2px rgba(77, 200, 143, 0.72);
}

.check-out-card-line {
  width: 65px;
  height: 13px;
  background-color: #80ea69;
  border-radius: 2px;
  margin-top: 7px;
}

@media only screen and (max-width: 480px) {
  .check-out-container {
    transform: scale(0.7);
  }

  .check-out-container:hover {
    transform: scale(0.74);
  }

  .check-out-new {
    font-size: 18px;
  }
  .UserCardWrapper {
    justify-content: center;
  }
  #UserCard {
    width: 90%;
  }
}

.check-out-buttons {
  width: 8px;
  height: 8px;
  background-color: #379e1f;
  box-shadow: 0 -10px 0 0 #26850e, 0 10px 0 0 #56be3e;
  border-radius: 50%;
  margin-top: 5px;
  transform: rotate(90deg);
  margin: 10px 0 0 -30px;
}

.check-out-container:hover .check-out-card {
  animation: slide-top 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) both;
}

.check-out-container:hover .check-out-post {
  animation: slide-post 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-70px) rotate(90deg);
    transform: translateY(-70px) rotate(90deg);
  }

  60% {
    -webkit-transform: translateY(-70px) rotate(90deg);
    transform: translateY(-70px) rotate(90deg);
  }

  100% {
    -webkit-transform: translateY(-8px) rotate(90deg);
    transform: translateY(-8px) rotate(90deg);
  }
}

.check-out-post {
  width: 63px;
  height: 75px;
  background-color: #dddde0;
  position: absolute;
  z-index: 11;
  bottom: 10px;
  top: 120px;
  border-radius: 6px;
  overflow: hidden;
}

.check-out-post-line {
  width: 47px;
  height: 9px;
  background-color: #545354;
  position: absolute;
  border-radius: 0px 0px 3px 3px;
  right: 8px;
  top: 8px;
}

.check-out-post-line:before {
  content: "";
  position: absolute;
  width: 47px;
  height: 9px;
  background-color: #757375;
  top: -8px;
}

.check-out-screen {
  width: 47px;
  height: 23px;
  background-color: #ffffff;
  position: absolute;
  top: 22px;
  right: 8px;
  border-radius: 3px;
}

.check-out-numbers {
  width: 12px;
  height: 12px;
  background-color: #838183;
  box-shadow: 0 -18px 0 0 #838183, 0 18px 0 0 #838183;
  border-radius: 2px;
  position: absolute;
  transform: rotate(90deg);
  left: 25px;
  top: 52px;
}

.check-out-numbers-line2 {
  width: 12px;
  height: 12px;
  background-color: #aaa9ab;
  box-shadow: 0 -18px 0 0 #aaa9ab, 0 18px 0 0 #aaa9ab;
  border-radius: 2px;
  position: absolute;
  transform: rotate(90deg);
  left: 25px;
  top: 68px;
}

@keyframes slide-post {
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
}

.check-out-dollar {
  position: absolute;
  font-size: 16px;
  font-family: "Lexend Deca", sans-serif;
  width: 100%;
  left: 0;
  top: 0;
  color: #4b953b;
  text-align: center;
}

.check-out-container:hover .check-out-dollar {
  animation: fade-in-fwd 0.3s 1s backwards;
}

@keyframes fade-in-fwd {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
