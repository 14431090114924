/* CONACTPAGE STYLING */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Contact * {
  font-family: "Nunito Sans", sans-serif;
  transition: all 500ms ease;

  animation: componrntrenser 1.5s ease;
}
.Contact {
  width: 100%;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
}
.Contact > div {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.Contact > div > div:not(.map) {
  width: 40%;
}
.adress h2 {
  font-size: 1.6em;
  font-weight: bold;
}
.adress h2.cityname {
  font-size: 1.4em;
  font-weight: 300;
}
.adress {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.adress p a{
  text-decoration: none;
  color: black;
}
.adress p {
  margin: 10px 0px;
  padding-right: 15px;
  font-size: 1.1em;
}
.adress h3 {
  margin-top: 1em;
  color: #7464d1;
}
.adress span {
  color: #7464d1;
}
.map {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5em;
  display: flex;
  justify-content: center;
}
.map iframe {
  width: 85%;
}
.Contact > h1,
#Enquiry > h1 {
  height: 30%;
  display: flex;
  align-items: flex-end;
  word-break: keep-all;
  padding-left: 10%;
  margin: 1em 0px;
  font-size: 3em;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  color: #501a77;
}
#Enquiry h1 {
  align-self: flex-start;
  margin-left: 12%;
}
.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.form > div {
  width: 45%;
  display: flex;

  flex-direction: column;
}
.form > div > input {
  padding: 7px;
  height: 3em;
  border-radius: 3px;
  outline: none;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
}
.form button {
  padding: 12px 24px;
  background-color: #501a77;
  color: white;
  border: none;
  border-radius: 7px;
  margin-top: 3em;
}
#message div * {
  margin: 0%;
}
#message input[type="checkbox"] {
  margin-right: 5px;
}
#message div {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}
#message {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#message textarea {
  height: 8em;
  resize: none;
  padding: 7px;
  border-radius: 3px;
  outline: none;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
}
#Enquiry {
  padding-top: 20vh;
  padding-bottom: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#Enquiry .form {
  width: 60%;
}

#messageBox span {
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
}
#messageBox div {
  width: 400px;
  height: 200px;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 10px black;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: messageboxani 500ms ease-in-out;
}

@keyframes messageboxani {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
#messageBox {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AllContacts{
  margin-top: 20vh;
  padding: 0px 10%;
}

.AllContacts-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.AllContacts-container p
{
  margin: 10px 0px;
  padding-right: 15px;
  font-size: 1.1em;
  text-wrap: balance;
}
.AllContacts-container p a {
  color: black;
  text-decoration: none;
}
.AllContacts-container p.addresssss {
  text-transform: capitalize;
  
}
.AllContacts-container h3 {
  margin-top: 1em;
  color: #7464d1;
}
.AllContacts-container span {
  color: #7464d1;
}
.AllContacts-container h2 {
  font-size: 1.6em;
  font-weight: bold;
  text-transform:uppercase;
}
.AllContacts h1 {
  height: 30%;
  display: flex;
  align-items: flex-end;
  word-break: keep-all;
  margin: 1em 0px;
  font-size: 3em;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  color: #501a77;
}

.line{
  width: 100%;
  height: 2px;
  background-color: #501a7751;
  margin: 10px 0px;
}
.all-contact-Link{
  text-decoration: none;
  color: white ;
  background-color: #501a77;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 5px;
  
  font-weight: 500;
  margin: 10px 0px;
}

.AllContacts-card{
  transition: all 500ms ease;
}
@media only screen and (max-width: 750px) {
  .Contact {
    margin-top: 15vh !important;
  }
  .Contact > div {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 4em;
    justify-content: center;
  }
  .Contact > div > div {
    width: 85% !important;
  }
  .Contact > h1 {
    width: 100%;
    align-items: center !important;
    justify-content: center;
    padding-left: 0% !important;
    margin: 0.5em 0px !important;
  }
  .form button {
    padding: 12px 24px;
    color: white;
    border: none;
    width: 95%;
    border-radius: 7px;
    margin-top: 1.5em !important;
  }
  .map iframe {
    width: 100%;
  }
  #Enquiry > h1 {
    font-size: 2em;
    margin-left: 2.5%;
  }
  #Enquiry .form {
    width: 95% !important;
  }
  #Enquiry h1 {
    padding-left: 1%;
  }
  .AllContacts{
  margin-top: 15vh;
  padding: 0px 5%;
}
}