#AdminNavbar nav {
  background-color: black;
  color: white; 
  padding: 10px;
  width: calc(100%-10px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#AdminNavbar .adminMenubar a.admin-active {
  background-color: white;
  border-radius: 10px;
  color: black;
  font-weight: bold;
}
#AdminNavbar .adminMenubar a {
  color: white;
  text-decoration: none;
  padding: 10px;
}
#AdminNavbar .adminMenubar {
  width: 100%;
  background-color: black;
  position: fixed;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 10vh;
  color: white;
}

.AdminForms form #x-mark {
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
}
.AdminForms form {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  color: black;
}
.AdminForms {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: absolute; 
  top: 0px;
  right: 0px;
  background-color: rgba(128, 128, 128, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* USER STYLING */
#AdminViewUsers{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  flex-direction: column;
}

.admin-userview-card {
  border-radius: 20px;
  background: #f5f5f5;
  position: relative;
  padding: 1.8rem;
  border: 2px solid #c3c6ce;
  transition: 0.5s ease-out;
  overflow: visible;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.admin-userview-card p{
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0px;
}

/* Search Bar */
.search-div {
  margin: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.search-div input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Grid Styling */
.users-grid {
  display: grid;
  gap: 10px;
  padding: 20px;
  margin-bottom: 10vh;
}

.grid-header {
  grid-template-columns: 1fr 5fr 5fr 5fr 3fr 3fr;
  display: grid;
  background-color: #f4f4f4;
  
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
}

.grid-row {
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 5fr 3fr 3fr;
  align-items: center;
  
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.grid-row:hover {
  background-color: #f9f9f9;
}

.grid-header span,
.grid-row span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.view-btn {
  padding: 5px 10px;
  font-size: 14px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
}

.view-btn:hover {
  background-color: rgb(107, 107, 107);
  cursor: pointer;
}

/* User Transaction */

.transactionContainer{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  padding-bottom: 12vh;
}
.transactionContainer > div{
  border-radius: 10px;
  border: 1px solid rgb(119, 119, 119);
 transition: all 200ms ease-in-out;
  padding: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 2fr;
}


.transactionContainer > div:hover{
  transform: scale(1.01);
border: 1px solid rgb(0, 0, 0);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.204);
}

.ProductsTable{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ProductsTable > div{
  border-radius: 10px;
  border: 1px solid rgb(119, 119, 119);
  padding: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 3fr 3fr 2fr 2fr 2fr;
}
/* From Uiverse.io by halitgurpinar */
.radio-input input {
  display: none;
}

.radio-input {
  --container_width: 250px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  color: black;
  width: var(--container_width);
  overflow: hidden;
  border: 2px solid gray;
}

.radio-input label {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-weight: 600;
  letter-spacing: -1px;
  font-size: 14px;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 2);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}

.radio-input label:nth-child(1):has(input:checked) {
  color: #fff;
}

.radio-input label:nth-child(2):has(input:checked) {
  color: #212121;
}

.radio-input label:nth-child(1):has(input:checked)~.selection {
  background-color: green;
  display: inline-block;
}

.radio-input label:nth-child(2):has(input:checked)~.selection {
  background-color: red;
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked)~.selection {
  transform: translateX(calc(var(--container_width) * 0 / 2));
}

.radio-input label:nth-child(2):has(input:checked)~.selection {
  transform: translateX(calc(var(--container_width) * 1 / 2));
}

.filters{
  display: flex;
  margin: 10px;
  align-items: center;
  gap: 10px;
  justify-content: end;
}

.filters input[type="date"] {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
 
  background-color: #333333;
  color: white;
}
.filters button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  height: fit-content;
  cursor: pointer;
}


.AdminContact {
  padding: 20px;
  margin-bottom: 10vh;
  font-family: Arial, sans-serif;
}

.contactTable {
  width: 100%;
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.contactTable tbody{
  display: flex;
  flex-direction: column;
  gap: 20px;
}



.contactTable tbody tr {
   display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.2s;
}
.contactTable thead tr {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  display: grid;
  background-color: #f4f4f4;
  align-items: center;
  
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
}

.contactTable thead tr th,  
.contactTable tbody tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogBox {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dialogBox h2 {
  margin-top: 0;
}
 

/* PRODUCTS */

.Products-Container{
  padding: 20px;
margin-bottom: 20vh;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  align-items: center;
}

.one-adminproduct img{
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.one-adminproduct{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: fit-content;
  background-color: rgb(227, 227, 227);
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(119, 119, 119);

}

.ProductForm{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #33333353;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  
}
.ProductForm form button
{
  padding: 5px 10px;
    font-size: 14px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    grid-column: span 2;
}
.ProductForm form input{
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.ProductForm form{
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 700px;
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
  .ProductForm form span{
    display: flex;
    flex-direction: column;
  }