@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Inter:wght@700&family=Josefin+Sans:ital,wght@0,700;1,700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito&family=Oswald:wght@200..700&family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Playfair+Display:wght@500&family=Trocchi&display=swap");
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

body * {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
body {
  overflow-x: hidden;
  margin: 0%;
  padding: 0%;
}

#LandingPage * {
  transition: all 500ms ease-in-out;

  animation: componrntrenser 1.5s ease;
}
#LandingPage {
  height: 110vh;
  transition: all 500ms ease;
  width: 100vw;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
#LandingPage > div p {
  width: 30%;
}
#LandingPage > div h1 {
  font-size: 450%;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  /* margin: 10px; */
}
#LandingPage > div h2 {
  font-size: 300%;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
#LandingPage > div {
  box-shadow: inset 0 0 1000px 250px rgba(0, 0, 0, 0.9);
  height: 100%;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5%;
  justify-content: center;
}

#HomeServices {
  box-shadow: inset 0 0 1000px 350px rgba(0, 0, 0, 0.9);
  gap: 20%;
  display: flex;
  min-height: 100vh;
  color: white;
  background-size: cover;
  justify-content: center;
  transition: all 0.3s ease;
  font-family: "Nunito Sans", sans-serif;
  overflow: hidden !important;
}

.info-tab h1 {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  font-size: 35px;
  width: fit-content;
  text-transform: uppercase;
}
.info-tab p {
  width: 65%;
  font-size: 1.2em;
}
.info-tab h2 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 40px;
  text-transform: uppercase;
  margin: 15px 0px;
}
.info-tab {
  width: 40%;
  display: flex;
  flex-direction: column;
  margin: auto 0px;
  gap: 15px;
  transition: all 1s ease;
}
.intro-nav {
  margin: auto 0px;
  width: 25%;
  list-style: none;
  display: flex;
  flex-direction: column;
  transition: all 1s ease;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  text-transform: uppercase;
}
.intro-nav a {
  margin: 20px 0px;
  text-decoration: none;
  color: white;
  width: 100%;
  font-size: 22px;
  border-bottom: 2px solid white;
  transition: all 200ms ease-in-out;
}
.intro-nav a:hover {
  border-bottom: 2px solid #501a77;
  font-size: 30px;
}

#wecare {
  width: 100%;
  padding: 3em 0px;
  padding-top: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff8e3;
}
#wecare img {
  width: 55%;
}
#wecare p {
  width: 45%;
  font-size: 1.5em;
  margin: 0%;
  color: black;
  text-align: center;
}

#Stats > h1 {
  width: 40%;
  font-size: 2.5em;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;

  text-align: center;
}
#Stats {
  height: 70vh;
  width: 100%;
  /* box-shadow: 10px -40px 40px -10px rgba(0, 0, 0, 0.783); */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("/public/assets/Images/Home/statsections.jpg") center/cover;
  /* background: linear-gradient(to bottom, black, #efebee); */
}

#stats-cards h1 {
  font-size: 4em;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt";
}
#stats-cards {
  display: flex;
  width: 70%;
  gap: 1em;
}
#stats-cards div {
  width: 30%;
  text-align: center;
}

#SkilledIn {
  padding: 15vh 9vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#skilledimg {
  width: 40vw;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.362);
  height: 60vh;
}
.mobile-view-photo {
  display: none;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.362);
  margin: 10px 5px;
}
.side-text {
  width: 30vw;
  border-left: 1px solid black;
}
.side-text p {
  margin-bottom: 1em;
  width: 70%;
  padding-left: 0.5em;
  height: 40%;
}
.side-text h1 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  padding-left: 0.5em;
  margin: 1.5em 0px;
  height: 40%;
}

.side-text > div {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.know-more-btn {
  background: black;
  width: fit-content;
  text-decoration: none;
  margin-top: 2em;
  color: white;
  font-family: inherit;
  padding: 0.2em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em rgba(0, 0, 0, 0.776);
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
}

.know-more-btn .icon {
  background: black;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: inset 0 0 1.6em -0.6em rgba(0, 0, 0, 0.776);
  right: 0.3em;
  transition: all 0.3s;
}

.know-more-btn:hover .icon {
  width: calc(100% - 0.6em);
}

.know-more-btn .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: white;
}

.know-more-btn:hover .icon svg {
  transform: translateX(0.1em);
}

.know-more-btn:active .icon {
  transform: scale(0.95);
}

#specilizedIn {
  font-family: "Caveat", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  margin: 0%;
  margin-bottom: 1em;
  font-size: 1.5em;
  padding: 0%;
  width: 50vw;
}

#BrainTakeForWho {
  background-color: #fff8e3;
  padding: 5vh 0px;
}
.btfw-navbae,
.btfw-infotab {
  margin-bottom: 5em;
  width: 80%;
  margin: 10vh auto;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.btfw-navbae span:hover {
  border-top: 4px solid #501a77;
}
.btfw-navbae span {
  border-top: 4px solid black;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  width: 23%;
  padding-top: 10px;
}

.btfw-infotab p {
  font-size: 1.2em;
  margin: 10px 0px;
}
.btfw-infotab > * {
  width: 45%;
  border-radius: 15px;
}
.btfw-infotab > img {
  object-fit: cover;
}
.btfw-infotab h1 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 3em;
}

.Testimonial *,
#Acheivements *,
#BrainTakeForWho *,
#SkilledIn * {
  transition: all 500ms ease;
}
.Testimonial {
  transition: all 500ms ease;
  max-width: 124rem;
  background-color: #2d9596;
  margin: 0 auto;
  padding: 2em 0px;
}

.heading {
  margin-left: 10%;
  padding: 5vh 0;
  color: white;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 550;
  font-style: normal;
  font-size: 3.5rem;
}

.swiper_container {
  min-height: 60vh;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 20em !important;
  min-height: 57vh !important;
  position: relative;
  box-shadow: 0 15px 50px rgb(0, 0, 0, 0.2);
  filter: blur(4px);
}
.swiper-slide-active {
  filter: blur(0px);
}
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30em;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider-controler .slider-arrow {
  background: #8d1ddb;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  color: white;
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow::after {
  content: "";
}

.one-testimonial {
  width: 16em !important;
  min-height: 500px !important;
  display: flex;
  flex-direction: column;
  padding: 2vh 2em;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
}

.one-testimonial p {
  font-size: 1.2em;
}
.one-testimonial i {
  font-size: 3em;
  align-self: flex-end;
  -webkit-text-stroke: 2px grey;
}
.whowrotethat {
  display: flex;
  align-items: center;
  gap: 10px;
}
.whowrotethat h3 {
  color: #8d1ddb;
}
.whowrotethat h3,
.whowrotethat p {
  margin: 0%;
}

#Acheivements > h1 {
  font-size: 3.5em;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
#Acheivements {
  padding: 2em 10%;
}
#Acheivements > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.one-acheivement h4,
.one-special-acheivement h4 {
  text-transform: uppercase;
  color: #8d1ddb;
  margin-top: 10px;
  font-size: 1.3em;
}
.one-special-acheivement p {
  font-size: 1.6em;
  padding: 15px 0px;
  margin-right: 2em;
  /* border-bottom: 1px solid black; */
}
.one-acheivement p {
  min-height: 250px;
  font-size: 1.2em;
  padding: 15px 0px;
  /* border-bottom: 1px solid black; */
}
.one-acheivement {
  width: calc(33% - 2em);
  display: flex;
  flex-direction: column;
}
.one-acheivement img {
  height: 450px;
  margin-top: 15px;
  width: 100%;
  object-fit: contain;
}
.one-special-acheivement div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.one-special-acheivement {
  width: 80vw;
  display: flex;
  margin-bottom: 4em;
  flex-direction: row-reverse;
}
.one-special-acheivement img {
  object-fit: contain;
  margin-top: 15px;
  width: 60%;
}

@media screen and (max-width: 800px) {
  /* LANDING PAGE */

  #LandingPage > div p {
    width: 90%;
  }
  #LandingPage > div h2 {
    font-size: 250%;
    margin: 10px 0px;
  }
  #LandingPage > div h1 {
    font-size: 350%;
    margin: 10px 0px;
  }
  #LandingPage > div {
    box-shadow: inset 0 0 925px 143px rgba(0, 0, 0, 0.8);
  }
  #LandingPage {
    height: 100vh;
  }

  /* WE CARE SECTION */

  #wecare img {
    width: 90%;
  }
  #wecare p {
    margin-top: 10px;
    width: 90%;
  }

  /* OUR SERVICES */

  #HomeServices {
    box-shadow: inset 0 0 1000px 162px rgba(0, 0, 0, 0.85);
  }
  .info-tab {
    display: none;
  }
  .intro-nav {
    width: 90%;
    align-items: center;
    padding: 0%;
  }

  /* STATS */

  #Stats > h1 {
    width: 90%;
    font-size: 2.5em;
  }
  #stats-cards {
    flex-direction: column;
    width: 90%;
    gap: 0em;
  }
  #stats-cards div {
    width: 100%;
    text-align: center;
  }
  #Stats {
    height: fit-content;
    padding: 1em 0px;
  }
  #stats-cards h1 {
    font-size: 3em;
    margin: 10px 0px;
  }
  #skilledimg {
    width: 100vw;
    object-fit: contain;
    height: 30vh;
    position: sticky;
    top: 0%;
  }

  /* Skilled IN */

  #SkilledIn {
    flex-direction: column-reverse;
  }

  #skilledimg {
    display: none;
  }

  .side-text {
    width: 100%;
  }
  .side-text p {
    width: 90%;
  }
  .mobile-view-photo {
    display: block;
  }
  .side-text > div {
    height: fit-content;
  }

  .btfw-infotab {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }

  .btfw-navbae {
    padding: 10px;
    width: 90%;
    background-color: #d8c281a5;
    border-radius: 10px;
  }

  .btfw-navbae span:hover {
    border-top: 2px solid #501a77;
  }
  .btfw-navbae span {
    border-top: 2px solid black;

    font-size: 16px;

    min-width: fit-content;
  }

  .btfw-infotab > * {
    width: 90%;
    border-radius: 15px;
  }
  .btfw-infotab h1 {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 3em;
  }

  .slider-controler {
    display: none;
  }
  .heading {
    margin: 0%;
    font-size: 250%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 1000px) {
  /* Achievemetns */
  #Acheivements > h1 {
    font-size: 250%;
    word-wrap: break-word;
    word-break: break-all;
  }
  #Acheivements {
    padding: 2em 5%;
  }

  .one-acheivement {
    width: 100%;
  }
  .one-acheivement img {
    height: fit-content;
  }
  .one-acheivement p {
    min-height: fit-content;
  }
  .one-special-acheivement {
    width: 100%;

    margin-bottom: 0em;
    flex-direction: column;
  }
  .one-special-acheivement img {
    width: 100%;
  }
  .one-special-acheivement p {
    font-size: 1.2em;
    margin-right: 0em;
  }
}
